import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainYellow,
  screen,
  transHover,
} from "../components/variables";
import { ImArrowDown2 } from "react-icons/im";
import { motion, AnimatePresence } from "framer-motion";

const Wrapper = styled.div`
  background: ${mainWhite};
  padding: 34px 0 0 0;
  min-height: 60vh;
  @media ${screen.small} {
    padding: 0;
    min-height: calc(100vh - 555px);
  }

  .hero {
    background-image: ${(props) => `url(${props.background_image})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 110px 0;
    }
    @media ${screen.small} {
      padding: 140px 0;
    }

    .heading {
      color: ${mainWhite};
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }

    &::after {
      @media ${screen.xsmall} {
        content: "";
        width: 120px;
        height: 120px;
        bottom: -60px;
        right: -60px;
        background: ${mainWhite};
        display: block;
        position: absolute;
        transform: rotate(45deg);
      }
      @media ${screen.small} {
        width: 150px;
        height: 150px;
        bottom: -75px;
        right: -75px;
      }
    }
  }

  .questions {
    padding: 28px 0;
    @media ${screen.xsmall} {
      padding: 120px 0;
    }

    .heading {
      color: ${mainBlue};
      margin: 0 0 15px 0;
      font-size: 1.4rem;
      text-align: center;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        margin: 0 0 25px 0;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }

    .p {
      color: ${mainBlue};
      font-size: 0.95rem;
      margin: 0 0 20px 0;
      text-align: center;
    }

    .question-list {
      @media ${screen.xsmall} {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
      }
      @media ${screen.medium} {
        margin: 0;
      }

      &__each {
        margin: 0 0 13px 0;
        overflow: hidden;
        @media ${screen.xsmall} {
          width: calc(50% - 40px);
          margin: 20px;
        }

        .box {
          border: 2px solid #e8e8ea;
          text-align: center;
          padding: 28px 40px 20px 40px;
          cursor: pointer;
          @media ${screen.xsmall} {
            padding: 40px 10px;
          }
          @media ${screen.small} {
            padding: 40px;
          }

          .icon {
            width: 62px;
            height: 62px;
            @media ${screen.small} {
              width: 86px;
              height: 86px;
            }
          }

          .heading {
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
            color: ${mainBlue};
            margin: 15px 0;
            transition: ${transHover};
            @media ${screen.xsmall} {
              font-size: 1.1rem;
              margin: 25px 0;
            }
            @media ${screen.small} {
              font-size: 1.5rem;
            }
            @media ${screen.medium} {
              font-size: 1.65rem;
            }
          }

          .arrow {
            color: ${mainYellow};
            font-size: 1.4rem;
            transition: 0.26s all ease-in-out;
            display: block;
            @media ${screen.xsmall} {
              font-size: 1.6rem;
            }
          }

          &--active {
            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        .description {
          background: #e8e8ea;
          padding: 25px;
          @media ${screen.xsmall} {
            margin: 6px 0 0 0;
            padding: 10px 40px 40px 40px;
          }
          @media ${screen.small} {
            padding: 30px 60px 60px 60px;
          }

          p {
            font-size: 0.95rem;
            margin: 0 0 20px 0;
          }

          strong {
            display: inline-block;
            @media ${screen.xsmall} {
              margin: 30px 0 0 0;
            }
          }
        }
      }
    }
  }
`;

const FaqsPage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    title,
    background_image,
    question_list,
  } = data.content.data;

  const [currentActive, setCurrentActive] = useState([]);

  const handleClick = (index) => {
    if (currentActive.indexOf(index) === -1) {
      setCurrentActive([...currentActive, index]);
    } else {
      const filtered = currentActive.filter((item) => item !== index);
      setCurrentActive(filtered);
    }
  };

  const isActive = (index) => {
    return currentActive.indexOf(index) !== -1;
  };

  return (
    <Layout rotatedSquareColour={mainWhite}>
      <Seo title={title_tag || "FAQ"} description={meta_description} />

      <Wrapper background_image={background_image.url}>
        <header className="hero">
          <div
            className="inner-wrapper flex"
            data-sal="fade"
            data-sal-duration="500"
          >
            <h1 className="heading">{title.text}</h1>
          </div>
        </header>
        <div className="questions">
          <div className="inner-wrapper">
            <ul className="question-list">
              {question_list.map((item, i) => (
                <li
                  className="question-list__each"
                  key={item.heading.text}
                  data-sal="slide-up"
                  data-sal-duration="700"
                  data-sal-delay={`${i}00`}
                >
                  <motion.div
                    className={isActive(i) ? "box box--active" : "box"}
                    onClick={() => {
                      handleClick(i);
                    }}
                    whileTap={{ scale: 0.985 }}
                  >
                    <img
                      className="icon"
                      src={item.icon.url}
                      alt={item.icon.alt || "Icon"}
                    />
                    <h2 className="heading">{item.heading.text}</h2>
                    <span className="arrow">
                      <ImArrowDown2 />
                    </span>
                  </motion.div>
                  <AnimatePresence>
                    {isActive(i) && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        transition={{ type: "tween" }}
                        exit={{ opacity: 0 }}
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: item.description.html,
                        }}
                      />
                    )}
                  </AnimatePresence>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default FaqsPage;

export const dataQuery = graphql`
  {
    content: prismicFaqPage {
      data {
        meta_description
        title_tag
        background_image {
          url
        }
        title {
          text
        }
        question_list {
          heading {
            text
          }
          description {
            html
          }
          icon {
            alt
            url
          }
        }
      }
    }
  }
`;
